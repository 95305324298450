import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/articles-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Debugging`}</h1>
    <p>{`Debugging is part of a developer's job. It's a known fact that bugs happen and things break, so we put on our detective hat and try to hunt for the cause of the bug.`}</p>
    <p>{`I've decided to share with you my experience with a particular problem and how I went from debugging to finally fixing the issue.`}</p>
    <h2>{`The Problem`}</h2>
    <p>{`In `}<a parentName="p" {...{
        "href": "https://opsdroid.dev"
      }}>{`opsdroid`}</a>{` we use `}<inlineCode parentName="p">{`pyyaml`}</inlineCode>{` to load a configuration file written in `}<inlineCode parentName="p">{`yaml`}</inlineCode>{` that sets everything we need to make our bot work.`}</p>
    <p>{`Recently pyyaml has released version 5.1.1 and introduced a few changes to the library. Unfortunately, these changes broke a few things and the documentation was only available for older versions of the library.`}</p>
    <p>{`With this new version, the ability to use environmental variables and to include another `}<inlineCode parentName="p">{`yaml`}</inlineCode>{` file into the configuration file broke and we needed to fix the code if we wanted to update `}<inlineCode parentName="p">{`pyyaml`}</inlineCode>{` to the latest version.`}</p>
    <h2>{`Facing the problem`}</h2>
    <p>{`The initial stage of debugging can be pretty scary. You need to tackle the issue head-on, but you don't want to break things.`}</p>
    <p>{`Sometimes, you will feel that you have no idea where to start and that will make you procrastinate to avoid the unpleasant task of tackling a problem.`}</p>
    <p>{`In my case, I was afraid of touching the code since I didn't understand how the library worked and what exactly changed since the last time.`}</p>
    <p>{`The task seems too great to take on at first, because the documentation couldn't offer much help since it was outdated.`}</p>
    <h2>{`Getting Started`}</h2>
    <p>{`I've decided to have a look at our code first. I knew that the code worked fine in the previous version(`}<inlineCode parentName="p">{`4.2b1`}</inlineCode>{`). So the issue couldn't be coming from the code but the changes that this new `}<inlineCode parentName="p">{`pyyaml`}</inlineCode>{` introduced.`}</p>
    <p>{`After looking at the code, I headed to the pyyaml repository and looked at previous commits between version 4.2b1 and version 5.1.1. This was an attempt to get a glimpse of what changed and what stayed the same.`}</p>
    <p>{`I've tried to change a few things in opsdroid code, but no luck.`}</p>
    <h2>{`Research mode`}</h2>
    <p>{`I didn't have enough information and so I started my research. Going around StackOverflow and google to try and figure out if someone had the same issue as us.`}</p>
    <p>{`There were a few questions in StackOverflow that offered a few solutions to the problem, but when I tried to implement the same solutions, the opsdroid code was still broken.`}</p>
    <p>{`Pyyaml was updated just a few days ago so probably people haven't yet started updating their pyyaml dependencies and that might explain the lack of questions about these issues.`}</p>
    <p>{`I had only one option, go deep and look into the pyyaml source code and attempt to figure out a way to fix the problems we were having in opsdroid.`}</p>
    <h2>{`Trial and error`}</h2>
    <p>{`The next stage of debugging was trying a few different things to see if they would fix the problem. I was switching between my editor (pycharm), the terminal to run tox to see if the tests were still failing and firefox with the pyyaml tab.`}</p>
    <p>{`I needed to make changes to the code and figure out what was breaking, so I decided to copy the code that makes the environmental variables and the `}<inlineCode parentName="p">{`!include`}</inlineCode>{`   and paste it in a new file.`}</p>
    <p>{`Now I could run only that file and see if the changes that I added would fix the issue.`}</p>
    <p>{`After a few hours, I was able to pinpoint the cause of the problem as to why the environmental variables weren't working.`}</p>
    <p>{`In opsdroid, we are building a resolver for the yaml constructor - basically, we created our own yaml tag but didn't want to pass it all the time.`}</p>
    <p>{`The resolver translates the code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`test: !envvar NameOfEnvVar
`}</code></pre>
    <p>{`into`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`test: $NameOfEnvVar
`}</code></pre>
    <p>{`The new update was breaking our resolver, so now I needed to figure out how to fix it.`}</p>
    <h2>{`Back to the source code`}</h2>
    <p>{`Now that I had the cause of the issue, I knew what could help in trying to find the solution for the problem.`}</p>
    <p>{`I went back to the pyyaml source code and checked the `}<inlineCode parentName="p">{`resolvers.py`}</inlineCode>{` file and looked up at what changed with the `}<inlineCode parentName="p">{`implicit_resolver`}</inlineCode>{` code.`}</p>
    <p>{`Then went back to opsdroid code and tried to add the additional parameters to the function and attempt to run the tests.`}</p>
    <h2>{`Problem Fixed`}</h2>
    <p>{`All tests passed so I was pretty happy with it! Solving a problem is always an amazing feeling and the reason why I fell in love with code, so obviously being able to fix the broken code with little to no documentation was a bliss.`}</p>
    <p>{`Before submitting the PR to opsdroid I decided to test the bot while using an environmental variable set and included yaml file.`}</p>
    <p>{`I was happy to see that everything worked as expected.`}</p>
    <h2>{`Summing up`}</h2>
    <p><strong parentName="p">{`Don't be afraid of debugging a problem.`}</strong>{` `}</p>
    <p>{`It might be scary to tackle a big problem, but remember that breaking a big problem into small pieces will help you immensely.`}</p>
    <p><strong parentName="p">{`Check the documentation.`}</strong></p>
    <p>{`Use the documentation to guide you. Sometimes it's worth going back to the documentation and reread something that you think you know how to do. Sometimes you just trust your brain or intuition but the docs will show you the best way to do something.`}</p>
    <p><strong parentName="p">{`Dive into the source code.`}</strong></p>
    <p>{`If you are using open source and you think the problem that you are facing is because of a library. Maybe the latest update broke something.`}</p>
    <p>{`Don't be afraid to dig deep and read that library's source code. It might sound scary but you will get important information and will understand better how the library works.`}</p>
    <p><strong parentName="p">{`Test, fail, test, win.`}</strong></p>
    <p>{`Think about different ways to tackle the problem and test a different hypothesis. Sometimes you might think a problem resides in some place, but after testing other things you notice that the problem was actually coming from some other place.`}</p>
    <p>{`In my case, I thought the issue was inside the constructor or the loader and didn't look into the resolver until a day had passed.`}</p>
    <p><strong parentName="p">{`Sleep it off.`}</strong></p>
    <p>{`If you keep getting stuck. Try to walk away from your laptop or work on something else. Come back to the problem the next day and sometimes that helps a lot and you can see or think things that you couldn't before.`}</p>
    <p><strong parentName="p">{`Ask for help.`}</strong></p>
    <p>{`If you are still stuck and have no idea what to do to fix the problem. Try to ask a colleague for help or StackOverflow. `}</p>
    <p>{`Even if other people can't help you much because they haven't faced that problem, sometimes they can offer insight and offer you a different point of view and that might help you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      